OPSGREAT.page = function () {
    OPSGREAT.initSelect2();
    OPSGREAT.backtop();
    OPSGREAT.initMatchHeight();
    OPSGREAT.fixedContact();

    OPSGREAT.renderSeatScreenOneLCT();

    // OPSGREAT.renderSeatScreenFloorBelowRightLCT();
    // OPSGREAT.renderSeatScreenFloorBelowLeftLCT();
    // OPSGREAT.renderSeatScreenFloorAboveRightLCT();
    // OPSGREAT.renderSeatScreenFloorAboveLeftLCT();
    // var currentPage = $(location).attr('href');
    // if (currentPage.indexOf('#') != -1) {
    //     currentPage = currentPage.substring(0, currentPage.indexOf('#'));
    // }
    // var navLinkSet = $('.navbar-uts li a');
    // var activated = false;
    // navLinkSet.each(function () {
    //     if ($(this).attr('href') == currentPage) {
    //         $(this).parents('li').addClass('active');
    //         activated = true;
    //     }
    // });
    // if (activated == false) {
    //     currentPage = currentPage.substring(0, currentPage.lastIndexOf('/'));
    //     navLinkSet.each(function () {
    //         if ($(this).attr('href') == currentPage) {
    //             $(this).parents('li').addClass('active');
    //             activated = true;
    //         }
    //     });
    // }
    if ($(window).width() > 769) {
        $('.navbar-OPSGREAT .dropdown').hover(function () {
            $(this).find('.dropdown-menu').first().stop(true, true).delay(200).slideDown('fast');

        }, function () {
            $(this).find('.dropdown-menu').first().stop(true, true).delay(100).slideUp('fast');

        });

        $('.navbar .dropdown > a').click(function () {
            location.href = this.href;
        });

        $(".top_link li.search a").click(function (evt) {
            evt.preventDefault();
            $(this).next('.top_search').toggleClass('active');
        });
    }

    if (OPSGREAT.detect.isMobile()) {
        $(".navbar-toggle").on("click", function () {
            $(this).toggleClass("active");
            $(this).parents('header').toggleClass("active");
        });

        $("span.exclamation").click(function (evt) {
            evt.preventDefault();
            $(this).parents('.fixed_custom_contact').toggleClass('active');
        });

    }
    /* Sticky nav */
    $(window).on('scroll', function () {
        'use strict';
        if ($(this).scrollTop() > 110) {
            $('header.header-home').addClass("sticky");
        } else {
            $('header.header-home').removeClass("sticky");
        }
    });
    //sticky header-bunger
    $(window).on('scroll', function () {
        'use strict';
        if ($(this).scrollTop() > 110) {
            $('header.home-header-bunger').addClass("sticky");
        } else {
            $('header.home-header-bunger').removeClass("sticky");
        }
    });

    $(window).on('load', function () {
        'use strict';
        if ($(this).scrollTop() > 110) {
            $('header.header').addClass("sticky");
        } else {
            $('header.header').removeClass("sticky");
        }
        OPSGREAT._animate();
    });

    $('[data-page]').each(function () {
        var page = $(this).data('page');
        switch (page) {
            case 'page-homepage':

                break;

            default:
                break;
        }
    });
    if ($.fn.matchHeight !== undefined) {
        if ($('.new_inner').length > 0) {
            $('.new_inner').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            })
        }

        if ($('.matchHeight').length > 0) {
            $('.matchHeight').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            })
        }
    }

    if (OPSGREAT.detect.isDesktop()) {
        $('.nav-tabs.tab-new li').on('mouseenter', function () {
            $('.nav-tabs.tab-new li.active').removeClass('active');    // remove class from previous active item
            $(this).addClass('active');                     // item class to the current one
        }).on('mouseleave', function (e) {
            $('.nav-tabs.tab-new li.active').removeClass('active');    // remove class from previous active item
            $('.nav-tabs.tab-new li:first').addClass('active');                      // item class to the current one
        });
    }
    if ($('.lists_partner').length > 0 && $.fn.slick !== undefined) {
        $('.lists_partner').slick({
            dots: false,
            infinite: true,
            speed: 1000,
            autoplay: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            lazyLoad: 'progressive',
            autoplaySpeed: 5000,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    }
    if ($('.partner_slider').length > 0 && $.fn.slick !== undefined) {
        $('.partner_slider').slick({
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 300,
            slidesToShow: 7,
            slidesToScroll: 7,
            lazyLoad: 'progressive',
            autoplaySpeed: 5000,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    }
    console.log('DOM is loaded and ready for manipulation here. (^_^)')
}
OPSGREAT.init = function () {
    this.page();
}
OPSGREAT.includeHTMLElement("w3-include-footer");

DOCUMENT.ready(function () {
    OPSGREAT.init();
})

//bunger menu
//header-bunger
const burger = document.querySelector('.burger');
const navbar = document.querySelector('.navbar');
const navLink = document.querySelectorAll('.nav-link');

burger.addEventListener('click', () => {
    navbar.classList.toggle('nav-open');
    burger.classList.toggle('burger-open');
    navLink.forEach(link => {
        link.classList.toggle('nav-link-open');
    })
});

//search
$(".header-search .form-control").hide();
$(".link-search").on("click", function (e) {
    e.preventDefault();
    $(".header-search .form-control").toggleClass("show");
})

//search scroll

$(".home-header-bunger +.header-search .form-control").hide();
$(".home-header-bunger .link-search").on("click", function (e) {
    e.preventDefault();
    $(".home-header-bunger +.header-search .form-control").toggleClass("show");
})


$('.slider').slick({
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});

//loadmore
$(".morebox").slice(0, 6).show();

$(document).on('click', '.load-more', function (e) {
    console.log('click load more')
    //e.preventDefault();
    $(".morebox:hidden").slice(0, 3).slideDown();
    if ($(".morebox:hidden").length == 0) {
        $(".load-more").fadeOut('slow');
    }
    $('html,body').animate({
        scrollTop: $(this).offset().top - 450
    }, 1500);
});



//footer
$(".footer .thank-you").css("display", "none");
$(".thank-you").hide();
$(document).on('click', '.btn-subscibe', function (e) {
    console.log(1)
    $(".thank-you").show();
});

// tab scroll
$('.tabbable li a').on('click', function (e) {
    var href = $(this).attr('href');
    $('.section4 .content .tabbable').animate({
        scrollTop: $(href).offset().top - 150
    }, 'slow');
    e.preventDefault();
});

//slider home
$('.news_slider').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true
            }
        }
    ]
});


//slider necessary
$('.slider-b').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    dots: true,
    arrows: true,
    focusOnSelect: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true,
                dots: true
            }
        }
    ]
});

//slider insurance
$('.slider-a').slick({
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: true,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true
            }
        }
    ]
});

//scrollify home

var siri = false;
$(document).ready(function () {

    var let = $.scrollify({
        section: ".slide",
        sectionName: "section-name",
        interstitialSection: "",
        easing: "easeOutExpo",
        scrollSpeed: 1100,
        offset: 0,
        scrollbars: true,
        standardScrollElements: "",
        setHeights: true,
        overflowScroll: true,
        updateHash: false,
        touchScroll: true,

        before: function (index) {
            if (index == 3) {
                siri = true;
                $.scrollify.disable();
            } else {
                $.scrollify.enable();
            }
        },
        after: function () {
        },
        afterResize: function () {
        },
        afterRender: function () {
            if ($(window).width() < 767) {
                $.scrollify.disable()
            } else {
                $.scrollify.enable()
            }
        }
    });
    var lastScrollTop = 0;
    $(window).scroll(function (event) {
        var st = $(this).scrollTop();
        if (st > lastScrollTop) {
            // downscroll code
        } else {
            $.scrollify({
                section: ".slide",
                sectionName: "section-name",
                interstitialSection: "",
                easing: "easeOutExpo",
                scrollSpeed: 1100,
                offset: 0,
                scrollbars: true,
                standardScrollElements: "",
                setHeights: true,
                overflowScroll: true,
                updateHash: false,
                touchScroll: true,

                before: function (index) {
                },
                after: function () {
                },
                afterResize: function () {
                },
                afterRender: function () {
                }
            })
            lastScrollTop = st;
        }
    });

    // var table = $('#borrowTable').DataTable({
    //     dom: 'frt',
    //     ajax: 'https://gyrocode.github.io/files/jquery-datatables/arrays.json',
    //     drawCallback: function(){
    //         // If there is some more data
    //         if($('#btn-example-load-more').is(':visible')){
    //             // Scroll to the "Load more" button
    //             $('html, body').animate({
    //                 scrollTop: $('#btn-example-load-more').offset().top
    //             }, 1000);
    //         }
    //
    //         // Show or hide "Load more" button based on whether there is more data available
    //         $('#btn-example-load-more').toggle(this.api().page.hasMore());
    //     }
    // });
// remove hash name
    $.scrollify.move("section-name");


});
